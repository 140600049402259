import React from "react"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { MARKS, BLOCKS, INLINES } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import st from "./postGeneral-template.module.scss"
import PostRelatedContainer from "../components/postRelated-container"
import { Disqus } from "gatsby-plugin-disqus"
import Promocode from "../components/promocode"

const postGeneralTemplate = ({
  data: { post, site, latestPosts },
  pageContext: { pagePath, prevPost, nextPost },
}) => {
  const disqusConfig = {
    url: `${site.siteMetadata.url}/${pagePath}`,
    identifier: post.id,
    title: post.title,
  }

  return (
    <Layout>
      <SEO
        title={post.title}
        description=""
        image={post.thumbnail.fixed.src}
        pathname={pagePath}
        imageWidth={post.thumbnail.fixed.width}
        imageHeight={post.thumbnail.fixed.height}
        article
      />

      <article className={st.post}>
        <header>
          <div className={st.category}>{post.category}</div>
          <h1 className={st.postTitle}>{post.title}</h1>
          <time className={st.publishedTime}>{post.createdAt}</time>
          {/* <div className={st.postTags}>
            <a href="./">
              <span className={st.postTag}>sampletag</span>
            </a>
            <a href="./">
              <span className={st.postTag}>sampletag</span>
            </a>
          </div> */}
        </header>

        <div className={st.postBodyContainer}>
          {documentToReactComponents(post.body.json, {
            renderMark: {
              [MARKS.BOLD]: text => (
                <strong className={st.strong}>{text}</strong>
              ),
              [MARKS.ITALIC]: text => <em className={st.em}>{text}</em>,
              [MARKS.UNDERLINE]: text => (
                <span className={st.underline}>{text}</span>
              ),
            },
            renderNode: {
              [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
              [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
              [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
              [BLOCKS.PARAGRAPH]: (node, children) => {
                return <p>{children}</p>
              },
              [INLINES.HYPERLINK]: (node, children) => {
                const uri = node.data.uri
                if (uri.includes("youtube.com/embed")) {
                  return (
                    <span className={st.iframeContainer}>
                      <iframe
                        title={uri}
                        src={uri}
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        allowFullScreen
                        className={st.iframe}
                      ></iframe>
                    </span>
                  )
                } else {
                  return (
                    <a href={uri} target="_blank" rel="noopener noreferrer">
                      {children}
                    </a>
                  )
                }
              },
              [INLINES.EMBEDDED_ENTRY]: (node, children) => {
                const { promocode } = node.data.target.fields
                return (
                  <Promocode code={promocode ? promocode["en-US"] : null} />
                )
              },
              [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                const { title, description, file } = node.data.target.fields
                const contentTypes = file["en-US"].contentType
                const contentGroups = contentTypes.split("/")[0]

                switch (contentGroups) {
                  case "image":
                    return (
                      <figure className={st.imgContainer}>
                        <a
                          href={description ? description["en-US"] : null}
                          className={description ? st.hasLink : null}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <img
                            src={
                              title
                                ? `${file["en-US"].url}?w=920&q=80&fm=jpg&fl=progressive`
                                : null
                            }
                            alt={title ? title["en-US"] : null}
                          />
                          <figcaption className={st.imgCaption}>
                            {description ? title["en-US"] : null}
                          </figcaption>
                        </a>
                      </figure>
                    )
                  default:
                    return
                }
              },
            },
          })}
        </div>
      </article>

      <section className={st.pagenavContainer}>
        <div>
          {prevPost && (
            <Link to={`${prevPost.category}/${prevPost.slug}`}>Previous Post</Link>
          )}
        </div>
        <div>
          {nextPost && <Link to={`${nextPost.category}/${nextPost.slug}`}>Next Post</Link>}
        </div>
      </section>

      <section className={st.relatedContainer}>
        <header>
          <h1>Latest Posts</h1>
        </header>
        <PostRelatedContainer posts={latestPosts.nodes} />
      </section>

      <section className={st.comments}>
        <Disqus config={disqusConfig} />
      </section>
    </Layout>
  )
}

export default postGeneralTemplate

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        url
      }
    }
    latestPosts: allContentfulPostGeneral(
      sort: { fields: createdAt, order: DESC }
      limit: 3
    ) {
      nodes {
        id
        category
        title
        slug
        createdAt(formatString: "dddd MMM DD YYYY")
        featured
        thumbnail {
          title
          fluid(maxWidth: 600, maxHeight: 600, quality: 50) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    post: contentfulPostGeneral(slug: { eq: $slug }) {
      id
      title
      slug
      category
      featured
      createdAt(formatString: "dddd MMM DD YYYY")
      body {
        json
      }
      tags
      thumbnail {
        fixed(width: 500) {
          src
          width
          height
        }
      }
    }
  }
`
