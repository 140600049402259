import React from "react"
import st from "./postRelated-container.module.scss"
import PostRelatedCard from "../components/postRelated-card"

const PostRelatedContainer = props => (
  <section className={st.articleContainer}>
    {props.posts.map(post => (
      <PostRelatedCard
        key={post.id}
        id={post.id}
        category={post.category}
        title={post.title}
        slug={`${post.category}/${post.slug}`}
        createdAt={post.createdAt}
        featured={post.featured}
        thumbnail={post.thumbnail}
      />
    ))}
  </section>
)

export default PostRelatedContainer
