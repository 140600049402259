import React, { Component } from "react"
import st from "./promocode.module.scss"

// const Promocode = props => (
//   <input
//     type="text"
//     className={st.promocode}
//     value={props.code}
//     size={props.code.length}
//   />
// )
// export default Promocode

export default class Promocode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      copySuccess: false,
    }
  }

  copyCodeToClipboard = () => {
    const el = this.input
    el.select()
    document.execCommand("copy")
    this.setState({ copySuccess: true })
  }

  render() {
    return (
      <span className={st.promocodeContainer}>
        <input
          readOnly
          type="text"
          className={st.promocode}
          value={this.props.code}
          size={this.props.code.length}
          ref={input => (this.input = input)}
          onClick={() => { this.copyCodeToClipboard() }}
          onMouseUp={() => this.setState({ copySuccess: false })}
        />
        {this.state.copySuccess
          ? (() => {
              return <span className={st.copySuccess}>Copied</span>
            })()
          : null}
      </span>
    )
  }
}
