import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import st from "./postRelated-card.module.scss"

/* 
PROPS:
  id
  category
  title
  slug
  createdAt
  featured
  thumbnail {
    title
    fluid {
      ...
    }
  }
*/

const PostRelatedCard = props => (
  <article key={props.id} className={st.articleContainer}>
    <Link to={`/${props.slug}`}>
      <Img
        fluid={{ ...props.thumbnail.fluid, aspectRatio: 3 / 2 }}
        alt={props.thumbnail.title}
        className={st.img}
      />
    </Link>
    <div>
      <h1 className={st.category}>
        <Link to={`/${props.category}`}>{props.category}</Link>
      </h1>
      <Link to={`/${props.slug}`}>
        <h2 className={st.title}>{props.title}</h2>
        <time className={st.publishedTime}>{props.createdAt}</time>
      </Link>
    </div>
  </article>
)

export default PostRelatedCard
